import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { Col, Grid, Row, IconButton, Panel } from "rsuite";
import ContactPanelPerson from "components/ui/contactPanelPerson";
import ExternalSourcePanel from "components/ui/externalSourcePanel";
import ExternalSourcesLookup from "../ui/externalSourcesLookup";
import ExternalSourcesLookupTacnh from "../ui/externalSourcesLookupTacnh";
import HeaderProfile from "../section/headerProfile";
import LayoutMain from "components/layout/main";
import MapProfilePage from "components/map/mapProfilePage";
import NavBarProfile from "../ui/navBarProfile";
import SectionWrapper from "components/section/sectionWrapper";

import { Icon } from "@rsuite/icons";
import {
  BiLinkExternal,
  BiArrowToRight,
  BiCheck,
  BiCheckDouble,
  BiX,
} from "react-icons/bi";


const ProfilePage = ({ pageContext, data }) => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <LayoutMain>
      <MapProfilePage
        lat={pageContext.lat}
        lng={pageContext.lng}
        title={pageContext.title}
        type={pageContext.type}
        logo={pageContext.logo}
      />
      <HeaderProfile
        title={pageContext.title}
        address={pageContext.address}
        telephone={pageContext.telephone}
        website={pageContext.website}
        city={pageContext.city}
        type={pageContext.type}
      />
      {domLoaded && (
        <>
          {pageContext.type === "Ziekenhuis" && (
            <NavBarProfile
              title={pageContext.title}
              contactpersonen={pageContext.contact_1}
              protocollen={pageContext.sources}
              elearning={data.allElearningCsv.nodes}
              verband={data.allVerbandCsv.nodes}
              type={pageContext.type}
              color="#22d788"
              textColor="#fff"
            />
          )}
          {pageContext.type === "Trombosedienst" && (
            <NavBarProfile
              title={pageContext.title}
              contactpersonen={pageContext.contact_1}
              protocollen={pageContext.sources}
              elearning={data.allElearningCsv.nodes}
              verband={data.allVerbandCsv.nodes}
              type={pageContext.type}
              color="#d78822"
              textColor="#fff"
            />
          )}
          {pageContext.type === "Regionaal samenwerkingsverband" && (
            <NavBarProfile
              title={pageContext.title}
              contactpersonen={pageContext.contact_1}
              protocollen={pageContext.sources}
              elearning={data.allElearningCsv.nodes}
              verband={data.allVerbandCsv.nodes}
              type={pageContext.type}
              color="#2272d7"
              textColor="#fff"
            />
          )}
        </>
      )}
      {pageContext.contact_1 && (
        <SectionWrapper padded background>
          <Grid>
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <h3 id="contactpersonen" style={{ color: "#000" }}>
                  Contactpersonen
                </h3>
              </Col>
            </Row>
            <Row>
              {pageContext.contact_1 && (
                <ContactPanelPerson
                  title={pageContext.contact_1}
                  avatar={pageContext.contact_1_avatar}
                  role={pageContext.contact_1_functie}
                  email={pageContext.contact_1_email}
                  telephone={pageContext.contact_1_telefoon}
                  // fax
                  // note
                />
              )}
              {pageContext.contact_2 && (
                <ContactPanelPerson
                  title={pageContext.contact_2}
                  avatar={pageContext.contact_2_avatar}
                  role={pageContext.contact_2_functie}
                  email={pageContext.contact_2_email}
                  telephone={pageContext.contact_2_telefoon}
                />
              )}
              {pageContext.contact_3 && (
                <ContactPanelPerson
                  title={pageContext.contact_3}
                  avatar={pageContext.contact_3_avatar}
                  role={pageContext.contact_3_functie}
                  email={pageContext.contact_3_email}
                  telephone={pageContext.contact_3_telefoon}
                />
              )}
              {pageContext.contact_4 && (
                <ContactPanelPerson
                  title={pageContext.contact_4}
                  avatar={pageContext.contact_4_avatar}
                  role={pageContext.contact_4_functie}
                  email={pageContext.contact_4_email}
                  telephone={pageContext.contact_4_telefoon}
                />
              )}
              {pageContext.contact_5 && (
                <ContactPanelPerson
                  title={pageContext.contact_5}
                  avatar={pageContext.contact_5_avatar}
                  role={pageContext.contact_5_functie}
                  email={pageContext.contact_5_email}
                  telephone={pageContext.contact_5_telefoon}
                />
              )}
            </Row>
          </Grid>
        </SectionWrapper>
      )}
      {pageContext.type === "Regionaal samenwerkingsverband" && (
        <>
          <div style={{ backgroundColor: "#fceae9" }}>
            <SectionWrapper padded>
              <Grid>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <h3
                      id="regionaal-verband"
                      style={{ marginBottom: "20px", color: "#000" }}
                    >
                      Over dit regionaal samenwerkingsverband
                    </h3>
                    <p
                      style={{
                        lineHeight: "2",
                        fontSize: "18px",
                        width: "95%",
                      }}
                    >
                      {pageContext.description}
                    </p>
                  </Col>
                </Row>
              </Grid>
            </SectionWrapper>
          </div>
          <SectionWrapper padded background>
            <Grid>
              <Row>
                <Col xs={24}>
                  <h4
                    id="organisaties"
                    style={{ marginBottom: "20px", color: "#000" }}
                  >
                    Organisaties die onderdeel zijn van {pageContext.title}
                  </h4>
                </Col>
              </Row>
              <Row>
                <>
                  {data.allOrganisatiesCsv.nodes.map((node) => (
                    <Col xs={6}>
                      <Link
                        to={`/professional/organisatie/profiel/${node.slug}`}
                      >
                        <Panel
                          header={
                            <span style={{ fontWeight: "bold" }}>
                              {node.title}
                            </span>
                          }
                          bordered
                          style={{
                            backgroundColor: "white",
                            marginBottom: "10px",
                          }}
                        >
                          <p style={{ fontSize: "16px", width: "85%" }}>
                            {node.verband}
                          </p>
                          <IconButton
                            icon={<Icon as={BiArrowToRight} size="1.5em" />}
                            size="md"
                            appearance="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Bekijken
                          </IconButton>
                        </Panel>
                      </Link>
                    </Col>
                  ))}
                </>
              </Row>
            </Grid>
          </SectionWrapper>
        </>
      )}
      {pageContext.sources && (
        <SectionWrapper padded>
          <Grid>
            <Row style={{ marginBottom: "20px" }}>
              <Col xs={24}>
                <h3 id="lokale-protocollen" style={{ color: "#000" }}>
                  Lokale protocollen
                </h3>
              </Col>
              <Col>
                {pageContext.sources === "yes" && (
                  <p style={{ fontSize: "20px", padding: "20px 0" }}>
                    <BiCheckDouble style={{ fontSize: "20px" }} />
                    Ja, het {pageContext.title} heeft lokale protocollen voor
                    hun antistollingsbeleid. Deze protocollen zijn openbaar
                    toegankelijk. Zie onderstaande lijst voor de beschikbare
                    protocollen.
                  </p>
                )}
                {pageContext.sources === "locked" && (
                  <p style={{ fontSize: "20px", padding: "20px 0" }}>
                    <BiCheck style={{ fontSize: "20px" }} />
                    Ja, het {pageContext.title} heeft lokale protocollen voor
                    hun antistollingsbeleid. Deze protocollen zijn niet openbaar
                    beschikbaar.
                  </p>
                )}
                {pageContext.sources === "no" && (
                  <p style={{ fontSize: "20px", padding: "20px 0" }}>
                    <BiX style={{ fontSize: "20px" }} />
                    Nee, het {pageContext.title} heeft geen lokale protocollen
                    voor hun antistollingsbeleid.
                  </p>
                )}
              </Col>
            </Row>
            {data.allBronnenCsv.nodes.length > 0 && (
              <Row>
                <>
                  {data.allBronnenCsv.nodes.map((node) => (
                    <ExternalSourcePanel
                      title={node.title}
                      description={node.description}
                      source={node.source}
                      type={node.type}
                      category={node.category}
                      owner={node.owner}
                    />
                  ))}
                </>
              </Row>
            )}
            {pageContext.sourcesLookUp ===
              "Nijmeegs Expertisecentrum voor Complexe Farmacotherapie" && (
              <ExternalSourcesLookup />
            )}
            {pageContext.sourcesLookUp ===
              "Transmuraal Antistolling Centrum Noord-Holland" && (
              <ExternalSourcesLookupTacnh />
            )}
          </Grid>
        </SectionWrapper>
      )}
      {data.allElearningCsv.nodes.length > 0 && (
        <SectionWrapper padded background>
          <Grid>
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <h3 id="e-learning" style={{ color: "#000" }}>
                  E-learning
                </h3>
              </Col>
            </Row>
            <Row>
              <>
                {data.allElearningCsv.nodes.map((node) => (
                  <ExternalSourcePanel
                    title={node.title}
                    description={node.description}
                    link={node.source}
                    type={node.type}
                    category={node.category}
                    owner={node.owner}
                  />
                ))}
              </>
            </Row>
          </Grid>
        </SectionWrapper>
      )}
      {data.allVerbandCsv.nodes.length > 0 && (
        <div style={{ backgroundColor: "#fceae9" }}>
          <SectionWrapper padded>
            <Grid>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <h3
                    id="regionaal-verband"
                    style={{ marginBottom: "20px", color: "#000" }}
                  >
                    Regionaal verband
                  </h3>
                  <>
                    {data.allVerbandCsv.nodes.map((node) => (
                      <>
                        <h4 style={{ color: "#000", marginBottom: "10px" }}>
                          {node.title}
                        </h4>
                        <p style={{ fontSize: "16px", width: "85%" }}>
                          {node.description}
                        </p>
                        <Link
                          to={`/professional/organisatie/profiel/${node.slug}`}
                        >
                          <IconButton
                            icon={<Icon as={BiLinkExternal} size="1.5em" />}
                            size="md"
                            appearance="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Bekijken
                          </IconButton>
                        </Link>
                      </>
                    ))}
                  </>
                </Col>
              </Row>
            </Grid>
          </SectionWrapper>
        </div>
      )}
    </LayoutMain>
  );
};

export const query = graphql`
  query($title: String!, $verband: String!) {
    allBronnenCsv(filter: { owner: { eq: $title } }) {
      nodes {
        title
        description
        source
        type
        category
        subcategory
        owner
        locked
      }
    }
    allElearningCsv(filter: { owner: { eq: $title } }) {
      nodes {
        title
        description
        source
        type
        category
        subcategory
        owner
      }
    }
    allVerbandCsv(filter: { title: { eq: $verband } }) {
      nodes {
        title
        description
        type
        website
        slug
      }
    }
    allOrganisatiesCsv(filter: { verband: { eq: $title } }) {
      nodes {
        title
        type
        website
        city
        slug
      }
    }
  }
`;

export default ProfilePage;
