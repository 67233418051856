import React from "react";
import { Panel } from "rsuite";
import SectionWrapper from "../section/sectionWrapper";
import GoogleMapReact from "google-map-react";
import { isMobile } from "react-device-detect";

export default function MapProfilePage({ lat, lng, title, type, logo }) {
  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds();
    bounds.extend(new maps.LatLng(lat, lng));
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps) => {
    const bounds = getMapBounds(map, maps);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  return (
    <>
      <SectionWrapper>
        <div style={{ display: "flex", height: "33vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBUxhEM5nehVOVEUAg11-fQrDLmPc8gASg",
            }}
            defaultZoom={4}
            options={defaultProps}
            defaultCenter={defaultProps.center}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          >
            <Marker
              key="key"
              lat={lat}
              lng={lng}
              title={title}
              type={type}
              color={type === "Trombosedienst" ? "crimson" : "green"}
            />
          </GoogleMapReact>
        </div>
        <div
          style={{
            position: "absolute",
            top: isMobile ? "20px" : "60px",
            left: isMobile ? "20px" :  "120px",
          }}
        >
          {logo && (
            <Panel shaded style={{ backgroundColor: "white" }}>
              <img
                src={`/logo/organisaties/${logo}`}
                alt={title}
                style={{ width: isMobile ? "80px" : "200px", margin: "0 10px" }}
              />
            </Panel>
          )}
        </div>
      </SectionWrapper>
    </>
  );
}

const Marker = () => (
  <></>
);

const defaultProps = {
  center: { lat: 52.1009166, lng: 5.1362914 },
  zoom: 13,
  minZoom: 8,
  maxZoom: 13,
  styles: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          hue: "#ff0000",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#96caff",
        },
        {
          visibility: "on",
        },
      ],
    },
  ],
};
