import React, { useState } from "react";
import { Link } from "gatsby";
import { Navbar, Nav, Affix, Grid, Row, Col, Stack, Tag } from "rsuite";

export default function NavBarProfile({
  title,
  contactpersonen,
  protocollen,
  elearning,
  verband,
  type,
  color,
  textColor,
}) {
  const [isFixed, setIsFixed] = useState(false);

  // const color = type === "Ziekenhuis" ? "#22d788" : "#d78822";
  // const textColor = isFixed ? "#000" : "#FFFFFF";

  return (
    <>
    {contactpersonen.length > 0 && (
      <Affix onChange={() => setIsFixed((prevIsFixed) => !prevIsFixed)}>
        <Navbar
          appearance
          style={{
            backgroundColor: isFixed ? "white" : color,
            color: textColor,
            position: "relative",
            boxShadow: isFixed
              ? "0 2px 5px rgba(0,0,0,0.2)"
              : "0 0px 0px rgba(0,0,0,0.2)",
          }}
        >
          <Grid>
            <Row>
              <Col>
                <Stack>
                  {isFixed ? (
                    <>
                      <Tag
                        style={{
                          backgroundColor: color,
                          color: "#fff",
                        }}
                      >
                        {type}
                      </Tag>
                      <span
                        style={{ fontWeight: "bold", padding: "0 10px", color: color }}
                      >
                        {title}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <span style={{ fontSize: "11px", margin: "0 10px 0 15px", color: isFixed? "#000" : textColor }}>
                    Navigatie:
                  </span>
                  <Nav>
                    {contactpersonen.length > 0 && (
                      <Nav.Item>
                        <Link
                          to="#contactpersonen"
                          style={{ color: isFixed? "#000" : textColor, fontWeight: "bold" }}
                        >
                          Contactpersonen
                        </Link>
                      </Nav.Item>
                    )}
                    {protocollen.length > 0 && (
                      <Nav.Item>
                        <Link
                          to="#lokale-protocollen"
                          style={{ color: isFixed? "#000" : textColor, fontWeight: "bold" }}
                        >
                          Protocollen
                        </Link>
                      </Nav.Item>
                    )}
                    {elearning.length > 0 && (
                      <Nav.Item>
                        <Link
                          to="#e-learning"
                          style={{ color: isFixed? "#000" : textColor, fontWeight: "bold" }}
                        >
                          E-learning
                        </Link>
                      </Nav.Item>
                    )}
                    {verband.length > 0 && (
                      <Nav.Item>
                        <Link
                          to="#regionaal-verband"
                          style={{ color: isFixed? "#000" : textColor, fontWeight: "bold" }}
                        >
                          Regionaal verband
                        </Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Stack>
              </Col>
            </Row>
          </Grid>
        </Navbar>
      </Affix>
    )}
    </>
  );
}
