import React from "react";
import { Col, Avatar, Panel, Stack, IconButton, Tag } from "rsuite";
import AuthModal from "./authModal";
import ContactPanelPersonDetailModal from "./contactPanelPersonDetailModal";
import { isLoggedIn } from "../services/auth";
import { Icon } from "@rsuite/icons";
import { BiPhoneCall, BiMailSend } from "react-icons/bi";

export default function contactPanelPerson({
  title,
  avatar,
  role,
  telephone,
  email,
}) {
  const FirstLetter = title.substring(0, 1);
  return (
    <Col xs={24} md={12} lg={8}>
      <Panel
        bordered
        style={{ backgroundColor: "white", marginBottom: "10px" }}
        header={
          <Stack justifyContent="space-between">
            <span>{title}</span>
            <Avatar
              size="sm"
              circle
              style={{ background: "#000" }}
              src={avatar}
              imgProps={{ objectFit: "cover" }}
            >
              {FirstLetter}
            </Avatar>
          </Stack>
        }
      >
        <Tag>{role}</Tag>
        <Stack style={{ marginTop: "30px" }}>
          {email && (
            <>
              {isLoggedIn() && (
                <ContactPanelPersonDetailModal
                  title={title}
                  role={role}
                  email={email}
                  trigger={
                    <IconButton
                      icon={<Icon as={BiMailSend} size="1.5em" />}
                      circle
                      size="md"
                      style={{ marginRight: "10px" }}
                    />
                  }
                />
              )}
              {!isLoggedIn() && (
                <AuthModal
                  trigger={
                    <IconButton
                      icon={<Icon as={BiMailSend} size="1.5em" />}
                      circle
                      size="md"
                      style={{ marginRight: "10px" }}
                    />
                  }
                />
              )}
            </>
          )}
          {telephone && (
            <>
              {isLoggedIn() && (
                <ContactPanelPersonDetailModal
                  title={title}
                  role={role}
                  telephone={telephone}
                  trigger={
                    <IconButton
                      icon={<Icon as={BiPhoneCall} size="1.5em" />}
                      circle
                      size="md"
                      style={{ marginRight: "10px" }}
                    />
                  }
                />
              )}
              {!isLoggedIn() && (
                <AuthModal
                  trigger={
                    <IconButton
                      icon={<Icon as={BiPhoneCall} size="1.5em" />}
                      circle
                      size="md"
                      style={{ marginRight: "10px" }}
                    />
                  }
                />
              )}
            </>
          )}
        </Stack>
      </Panel>
    </Col>
  );
}
