import React from "react";
import { IconButton, Col, Panel, Tag } from "rsuite";

import { Icon } from "@rsuite/icons";
import { BiLinkExternal } from "react-icons/bi";

export default function ExternalSourcePanel({
  title,
  description,
  owner,
  source,
  type,
}) {
  return (
    <>
      <Col xs={24} style={{ marginBottom: "10px", padding: "0" }}>
        <Panel bordered style={{ backgroundColor: "white" }}>
          {type && <Tag style={{ marginRight: "10px" }}>{type}</Tag>}
          {owner && <Tag style={{ margin: "0" }}>{owner}</Tag>}
          <h4 style={{ margin: "10px 0", width: "90%" }}>{title}</h4>
          <p style={{ fontSize: "15px", marginBottom: "20px", width: "90%" }}>
            {description}
          </p>
          <a href={source} target="_blank" rel="noreferrer noopener">
            <IconButton
              icon={<Icon as={BiLinkExternal} size="1.5em" />}
              size="sm"
              appearance="primary"
            >
              Bekijken
            </IconButton>
          </a>
        </Panel>
      </Col>
    </>
  );
}
