import React, { useState } from "react";
import { Modal, Button, Tag, Stack, Panel } from "rsuite";

export default function ContactPanelPersonDetailModal({
  trigger,
  title,
  email,
  telephone,
  role,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div onKeyDown={handleOpen} onClick={handleOpen} aria-hidden="true">{trigger}</div>
      <Modal keyboard={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Stack>
            <h4 style={{ color: "#000", marginRight: "10px" }}>{title}</h4>
            <Tag>{role}</Tag>
          </Stack>
        </Modal.Header>
        <Modal.Body>
          {email && (
            <Panel header="E-mailadres" bordered>
              <p style={{ textTransform: "lowercase" }}>
                <a href={`mailto://${email}`}>{email}</a>
              </p>
            </Panel>
          )}
          {telephone && (
            <Panel header="Telefoon" bordered>
              <p>{telephone}</p>
            </Panel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Sluiten</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
