import React from "react";
import { Col, Row, Grid, Stack, Breadcrumb, Tag } from "rsuite";
import { Link } from "gatsby";
import SectionWrapper from "./sectionWrapper";

import { Icon } from "@rsuite/icons";
import { BiMap, BiPhoneCall, BiLinkExternal } from "react-icons/bi";

export default function HeaderProfile({
  title,
  address,
  telephone,
  website,
  city,
  type,
}) {
  return (
    <>
      <SectionWrapper padded>
        <Grid>
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/professional">Start</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/professional/organisatie-vinden">Regio's</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
              </Breadcrumb>
              <h2 style={{ marginBottom: "10px", color: "#000" }}>
                {title}{" "}
              </h2>

              <Stack spacing={20}>
                {type === "Ziekenhuis" && (
                  <Tag style={{ backgroundColor: "#22d788", color: "white" }}>{type}</Tag>
                )}
                {type === "Trombosedienst" && (
                  <Tag style={{ backgroundColor: "#d78822", color: "white" }}>
                    {type}
                  </Tag>
                )}
                {type === "Regionaal samenwerkingsverband" && (
                  <Tag style={{ backgroundColor: "#2272d7", color: "white" }}>
                    {type}
                  </Tag>
                )}
                {address ? (
                  <>
                    <Icon as={BiMap} size="2em" /> {address}, {city}
                  </>
                ) : (
                  <></>
                )}
                {telephone ? (
                  <>
                    <Icon as={BiPhoneCall} size="2em" /> {telephone}
                  </>
                ) : (
                  <></>
                )}
                {website ? (
                  <>
                    <Icon as={BiLinkExternal} size="2em" />{" "}
                    <a href={website} target="_blank" rel="noopener noreferrer">
                      Website
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </>
  );
}
