import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Row } from "rsuite";
import ExternalSourcePanel from "components/ui/externalSourcePanel";

export default function ExternalSourcesLookupTacnh() {
  const data = useStaticQuery(graphql`
    query {
      allBronnenCsv(
        filter: {
          owner: { eq: "Transmuraal Antistolling Centrum Noord-Holland" }
        }
      ) {
        nodes {
          title
          description
          source
          type
          category
          subcategory
          owner
          locked
        }
      }
    }
  `);
  return (
    <>
      <Row>
        <>
          {data.allBronnenCsv.nodes.map((node) => (
            <ExternalSourcePanel
              title={node.title}
              description={node.description}
              source={node.source}
              type={node.type}
              category={node.category}
              owner={node.owner}
            />
          ))}
        </>
      </Row>
    </>
  );
}
